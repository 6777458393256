<template>
  <TodayDelivery />
</template>

<script>
  import TodayDelivery from "../../shared/TodayDelivery";
  export default {
    name: "t-today-delivery-admin",
    components: {
      TodayDelivery,
    },
  };
</script>
