<template>
  <AllCollection />
</template>

<script>
  import AllCollection from "../../shared/AllCollection";
  export default {
    name: 't-all-collection-admin',
    components: {
      AllCollection,
    },
  };
</script>