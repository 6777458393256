const rules = {
  confirm: conf => v => (v === conf) || 'Does not match',
  required: v => !!v.trim() || 'This field is required',
  userId: v => (!!(String(v).match(/^\d{10}$/))) || 'Please enter a valid Mobile Number',
  email: v => !!(String(v || '').match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) || 'Please enter a valid Email',
  length: (len, max) => v => ((v.trim() || '').length >= len && (v.trim() || '').length <= max) || `Invalid character length, required ${len} - ${max}`,
  otp: v => !!(String(v).match(/^\d{6}$/)) || `Please enter a valid 6 digit OTP`,
  password: v => !!(String(v).match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,15}$/)) || 'Please enter a valid password, only 8 - 15 characters',
};
  
export default rules;