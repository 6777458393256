<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        <v-row align="center">
          <v-col cols="12" sm="6" md="4" lg="4" class="py-0">
            <v-select :items="deliveryBoys"
              v-model="deliveryBoy"
              label="Delivered By"
              color="blue-grey darken-4"
            >
              <template v-slot:append-outer>
                <div class="align-center pt-2">
                  <nobr>
                    {{deliveredQuantity}}
                  </nobr>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-card>
      <v-data-table hide-default-footer :headers="headers" :items="deliveries" item-key="name">
        <template v-slot:item.location="{ item }">
          <v-btn icon @click="editEnquiry(item.id)">
            <v-icon>mdi-map-marker</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.call="{ item }">
          <v-btn icon @click="editEnquiry(item.id)">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.move_to_enquiry="{ item }">
          <v-btn icon @click="editEnquiry(item.id)">
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "t-todaydelivery-table-shared",
    data() {
      return {
        deliveryBoys: ["All", "Chandra sekar", "Gokul"],
        deliveryBoy: "All",
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Quick Ref', value: 'quick_ref' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Location', value: 'location', sortable: false },
          { text: 'Call', value: 'call', sortable: false },
          { text: 'MoveToEnquiry', value: 'move_to_enquiry', sortable: false },
        ],
        deliveries: [
          {
            id: 1,
            name: 'Chandra sekar',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 2,
            name: 'Raja sekar',
            quick_ref: 'Raja Trichy',
            mobile: '832882009',
            quantity: '120',
            delivery_date: '2021-07-02',
          },
          {
            id: 3,
            name: 'Sathish VPK',
            quick_ref: 'sevapet',
            mobile: '832882009',
            quantity: '210',
            delivery_date: '2021-07-03',
          },
          {
            id: 4,
            name: 'Mohan store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 5,
            name: 'dept store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 6,
            name: 'some store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 7,
            name: 'chennai store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 8,
            name: 'pondy',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 9,
            name: 'lab',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 10,
            name: 'oil mill',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
        ],
      };
    },
    computed: {
      deliveredQuantity() {
        return this.deliveries.map(x => x.quantity).reduce((a, b) => parseFloat(a) + parseFloat(b)) + ' Kgs';
      }
    },
    methods: {
      editEnquiry(id) {
        console.log(id);
      },
    },
  };
</script>