import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    alert: {
      status: '',
      show: false,
      message: '',
    },
    isLoading: false,
    currentComponent: 't-home',
    toolbarTitle: 'Home',
  },
  actions: {
    setAlert({ commit }, alert) {
      commit('updateAlert', alert);
    },
    setLoading({ commit }, loading) {
      commit('updateLoading', loading);
    },
    setComponent({ commit }, component) {
      commit('updateComponent', component);
    },
  },
  mutations: {
    updateAlert(state, alert) {
      state.alert = alert;
    },
    updateLoading(state, loading) {
      state.isLoading = loading;
    },
    updateComponent(state, component) {
      state.currentComponent = component.name;
      state.toolbarTitle = component.title;
    },
  },
  modules: {
    auth, // auth namespaced
  },
});