<template>
  <v-app>
    <v-main>
      <router-view />
      <v-snackbar v-model="show" :color="status" :timeout="6000" bottom right>
        {{message}}
        <template v-slot:action="{ attrs }">
          <v-btn icon class="white--text" v-bind="attrs"
            @click="setAlert({status: '', show: false, message: ''})">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    name: 'App',
    computed: {
      ...mapState({
        status: state => state.alert.status,
        message: state => state.alert.message,
      }),
      show: {
        get() {
          return this.$store.state.alert.show;
        },
        set(val) {
          this.$store.dispatch('setAlert', { status: '', show: val, message: '' });
        },
      },
    },
    methods: {
      ...mapActions([
        'setAlert',
      ]),
    },
  };
</script>
