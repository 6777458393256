<template>
  <v-app-bar
    fixed
    color="primary"
    class="white--text"
  >
    <v-app-bar-nav-icon>
      <template slot>
        <v-icon color="white" class="white--text" 
          @click="$store.dispatch('setComponent', {name: 't-home', title: 'Home'})"
          :disabled="toolbarTitle == 'Home'">mdi-home</v-icon>
      </template>
    </v-app-bar-nav-icon>
    <v-toolbar-title>{{toolbarTitle}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="yellow"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="white">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-btn @click="$router.push({name: 'login'})">
              <v-icon>mdi-logout-variant</v-icon>Logout
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 't-nav-appbar',
    computed: {
      ...mapState({
        toolbarTitle: state => state.toolbarTitle,
      }),
    },
  };
</script>