<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="8" md="6" lg="4" class="py-0">
        <v-autocomplete
          v-model="value"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          color="blue-grey darken-4"
          hide-selected
          item-text="name"
          item-value="id"
          label="Search Customer"
          placeholder="Search by name / quick ref / mobile"
          prepend-inner-icon="mdi-account-search"
          return-object
          solo
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
    <EnquiryTable v-if="false" />
    <EnquiryForm />
  </v-container>
</template>

<script>
  import EnquiryTable from './EnquiryTable';
  import EnquiryForm from './EnquiryForm';

  export default {
    name: 't-enquiry-shared',
    components: {
      EnquiryTable,
      EnquiryForm,
    },
    data() {
      return {
        value: {},
        items: [{name: 'Chans', id: 1}, {name: 'Vel', id: 5}, {name: 'Sekar', id: 3}],
        isLoading: false,
        search: null,
      };
    },
    watch: {
      search(value) {
        console.log(value);
      },
      value(newValue) {
        console.log('from watcher', newValue);
      },
    },
  };
</script>