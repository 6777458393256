<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <span>Total Pending Amount: {{totalPendingAmount}}</span>
      </v-card-title>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="pendingcustomers"
        item-key="name"
        :search="search"
      >
        <template v-slot:item.call="{ item }">
          <v-btn icon @click="callCustomer(item.id)">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.view="{ item }">
          <v-btn icon @click="viewCustomer(item.id)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 't-pending-customers-table-shared',
    data() {
      return {
        search: '',
        headers: [
          { text: 'Call', value: 'call', sortable: false },
          { text: 'View', value: 'view', align: 'center', sortable: false },
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Quick Ref', value: 'quick_ref' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Pending Amount', value: 'pending_amount' },
          { text: 'Total Amount', value: 'total_amount' },
          { text: 'Delivered Date', value: 'delivered_date' },
          { text: 'Delivered By', value: 'delivered_by' },
        ],
        pendingcustomers: [
          {
            id: 1,
            name: 'Chandra sekar',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            pending_amount: '352',
            total_amount: '600',
            delivered_by: 'Chandru',
            delivered_date: '2021-07-01',
          },
          {
            id: 2,
            name: 'Raja sekar',
            quick_ref: 'Raja Trichy',
            mobile: '832882009',
            quantity: '120',
            pending_amount: '30',
            total_amount: '150',
            delivered_by: 'Gokul',
            delivered_date: '2021-07-02',
          },
          {
            id: 3,
            name: 'Sathish VPK',
            quick_ref: 'sevapet',
            mobile: '832882009',
            quantity: '210',
            pending_amount: '150',
            total_amount: '500',
            delivered_by: 'Nitish',
            delivered_date: '2021-04-03',
          },
          {
            id: 4,
            name: 'Mohan store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '150',
            pending_amount: '50',
            total_amount: '300',
            delivered_by: 'Chnadru',
            delivered_date: '2021-06-01',
          },
          {
            id: 5,
            name: 'dept store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '10',
            pending_amount: '200',
            total_amount: '600',
            delivered_by: 'Gokul',
            delivered_date: '2021-07-15',
          },
          {
            id: 6,
            name: 'some store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '200',
            pending_amount: '400',
            total_amount: '600',
            delivered_by: 'Nithish',
            delivered_date: '2021-07-01',
          },
          {
            id: 7,
            name: 'chennai store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            pending_amount: '100',
            total_amount: '600',
            delivered_by: 'Gokul',
            delivered_date: '2021-02-22',
          },
          {
            id: 8,
            name: 'pondy',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            pending_amount: '80',
            total_amount: '600',
            delivered_by: 'Nithish',
            delivered_date: '2021-08-01',
          },
          {
            id: 9,
            name: 'lab',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            pending_amount: '300',
            total_amount: '600',
            delivered_by: 'Chnadru',
            delivered_date: '2021-07-23',
          },
          {
            id: 10,
            name: 'oil mill',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            pending_amount: '60',
            total_amount: '300',
            delivered_by: 'Chnadru',
            delivered_date: '2020-05-01',
          },
        ],
      };
    },
    computed: {
      totalPendingAmount() {
        return '₹ ' + this.pendingcustomers.map(x => x.pending_amount).reduce((a, b) => parseFloat(a) + parseFloat(b));
      }
    },
    methods: {
      callCustomer(id) {
        console.log(id);
      },
      viewCustomer(id) {
        console.log(id);
      },
    },
  }
</script>