<template>
  <Enquiry />
</template>

<script>
  import Enquiry from '../../shared/Enquiry';

  export default {
    name: 't-enquiry-admin',
    components: {
      Enquiry,
    },
  };
</script>