<template>
  <div id="chart">
    <apexchart type="bar" height="350" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 't-admin-12-weeks-profit-chart',
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        series: [{
          name: 'Weekly Profit',
          data: [2779,3619,2728,2930,1995,2992]
        }],
        chartOptions: {
          title: {
            text: 'Last 6 Weeks Profit',
            style: {
              fontSize:  '24px',
              color:  '#304758',
            },
          },
          colors: ['#00b300'],
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
              columnWidth: '50%',
              dataLabels: {
                position: 'top',
              },
            }
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: 2
          },
          tooltip: {
            y: {
              formatter: function(value) {
                return '₹ ' + value;
              },
            },
          },
          grid: {
            show: true,
            row: {
              colors: ['#fff', '#f2f2f2']
            }
          },
          xaxis: {
            labels: {
              rotate: -45
            },
            categories: ['30-5-Sep-2021','23-29-Aug-2021','16-22-Aug-2021','9-15-Aug-2021','2-8-Aug-2021','26-1-Aug-2021'
            ],
            tickPlacement: 'on'
          },
          yaxis: {
            title: {
              text: 'Profit in Rupees',
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [50, 0, 100]
            },
          }
        },
      };
    },
  };
</script>