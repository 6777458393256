<template>
  <v-card>
    <v-card-title>
      <v-row align="center">
        <v-col cols="12" sm="6" md="4" lg="4" class="py-0">
          <span v-if="selected.length == 0">
            All Enquiries List({{enquiries.length}} - {{totalQuantity}})
          </span>
          <div v-else>
            <v-select
              :items="deliveryBoys"
              label="Delivered By"
              clearable
              color="blue-grey darken-4"
            >
            <template v-slot:append-outer>
              <div class="align-center">
                <nobr>
                  <v-btn icon>
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                  {{deliveredQuantity}}
                </nobr>
              </div>
            </template>
          </v-select>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="py-0">
          <v-text-field
          v-model="enquirySearch"
          append-icon="mdi-magnify"
          label="Search"
          color="blue-grey darken-4"
          clearable
        ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      hide-default-footer
      v-model="selected"
      :headers="headers"
      :items="enquiries"
      :search="enquirySearch"
      item-key="name"
      show-select
      :single-select="false"
    >
      <template v-slot:item.edit="{ item }">
        <v-btn icon @click="editEnquiry(item.id)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.call="{ item }">
        <v-btn icon @click="editEnquiry(item.id)">
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn icon @click="editEnquiry(item.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    name: 't-enquiry-table-shared',
    data() {
      return {
        enquirySearch: '',
        deliveryBoys: ['Chandra sekar', 'Gokul'],
        selected: [],
        headers: [
          { text: 'Edit', value: 'edit', align: 'center', sortable: false },
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Quick Ref', value: 'quick_ref' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Delivery Date', value: 'delivery_date' },
          { text: 'Call', value: 'call', sortable: false },
          { text: 'Delete', value: 'delete', sortable: false },
        ],
        enquiries: [
          {
            id: 1,
            name: 'Chandra sekar',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 2,
            name: 'Raja sekar',
            quick_ref: 'Raja Trichy',
            mobile: '832882009',
            quantity: '120',
            delivery_date: '2021-07-02',
          },
          {
            id: 3,
            name: 'Sathish VPK',
            quick_ref: 'sevapet',
            mobile: '832882009',
            quantity: '210',
            delivery_date: '2021-07-03',
          },
          {
            id: 4,
            name: 'Mohan store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 5,
            name: 'dept store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 6,
            name: 'some store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 7,
            name: 'chennai store',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 8,
            name: 'pondy',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 9,
            name: 'lab',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
          {
            id: 10,
            name: 'oil mill',
            quick_ref: 'chandru Trichy',
            mobile: '832882009',
            quantity: '20',
            delivery_date: '2021-07-01',
          },
        ],
      }
    },
    computed: {
      totalQuantity() {
        return this.enquiries.map(x => x.quantity).reduce((a, b) => parseFloat(a) + parseFloat(b)) + ' Kgs';
      },
      deliveredQuantity() {
        return this.selected.map(x => x.quantity).reduce((a, b) => parseFloat(a) + parseFloat(b)) + ' Kgs';
      }
    },
    methods: {
      editEnquiry(id) {
        console.log(id);
      },
    },
  };
</script>