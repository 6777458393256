<template>
  <div>
    <t-appbar />
    <v-main class="pt-10 mt-10">
      <div v-if="isLoading">
        <t-loading />
      </div>
      <div v-else v-cloak>
        <component v-bind:is="currentComponent"></component>
      </div>
    </v-main>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Home from './blocks/Home';
  import Enquiry from './blocks/Enquiry';
  import Loading from '../shared/Loading';
  import AppBar from '../shared/AppBar';
  import Dashboard from './blocks/Dashboard';
  import AllCollection from './blocks/AllCollection';
  import ManageCustomers from './blocks/ManageCustomers';
  import ManageInventory from './blocks/ManageInventory';
  import PendingCustomers from './blocks/PendingCustomers';
  import Sales from './blocks/Sales';
  import TodayDelivery from './blocks/TodayDelivery';

  export default {
    name: "t-admin-root-page",
    components: {
      't-home': Home,
      't-enquiry': Enquiry,
      't-loading': Loading,
      't-appbar': AppBar,
      't-dashboard': Dashboard,
      't-allcollection': AllCollection,
      't-managecustomers': ManageCustomers,
      't-manageinventory': ManageInventory,
      't-pendingcustomers': PendingCustomers,
      't-sales': Sales,
      't-todaydelivery': TodayDelivery,
    },
    computed: {
      ...mapState({
        isLoading: state => state.isLoading,
        currentComponent: state => state.currentComponent,
      }),
    },
  };
</script>
