<template>
  <div id="chart" style="width: 100%; min-width: 1000px; overflow-x: scroll;overflow-y: hidden;overflow: hidden;">
    <apexchart type="bar" height="350" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 't-admin-customer-chart',
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        series: [{
          name: 'Weekly Customers',
          data: [2779,3619,2728,2930,1995,2992,3919,2770,3195,4628,4749,4929,6266,3204,0,3906,3396,3931,3784,3607,2958,2685,3489,3476]
        }],
        chartOptions: {
          title: {
            text: 'Last 24 Weeks New Customers Registration',
            style: {
              fontSize:  '24px',
              color:  '#304758',
            },
          },
          colors: ['#00b300'],
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
              columnWidth: '50%',
              dataLabels: {
                position: 'top',
              },
            }
          },
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: 2
          },
          tooltip: {
            y: {
              formatter: function(value) {
                return value;
              },
            },
          },
          grid: {
            show: true,
            row: {
              colors: ['#fff', '#f2f2f2']
            }
          },
          xaxis: {
            labels: {
              rotate: -45
            },
            categories: ['30-5-Sep-2021','23-29-Aug-2021','16-22-Aug-2021','9-15-Aug-2021','2-8-Aug-2021','26-1-Aug-2021','19-25-Jul-2021','12-18-Jul-2021','5-11-Jul-2021','28-4-Jul-2021','21-27-Jun-2021','14-20-Jun-2021','7-13-Jun-2021','31-6-Jun-2021','24-30-May-2021','17-23-May-2021','10-16-May-2021','3-9-May-2021','26-2-May-2021','19-25-Apr-2021','12-18-Apr-2021','5-11-Apr-2021','29-4-Apr-2021','22-28-Mar-2021'
            ],
            tickPlacement: 'on'
          },
          yaxis: {
            title: {
              text: 'Customers Count',
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.85,
              opacityTo: 0.85,
              stops: [50, 0, 100]
            },
          }
        },
      };
    },
  };
</script>