<template>
  <div id="chart" style="width: 100%; min-width: 1000px; overflow-x: scroll;overflow-y: hidden;overflow: hidden;">
    <apexchart type="area" height="350" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 't-admin-week-branded-sales-chart',
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        series: [{
          name: 'Branded',
          data: [1787, 725, 476, 2156, 1106, 2632, 1274, 2506, 1709, 2972, 568, 4658, 3960, 749, 0, 3749, 438, 2078, 2940, 2827, 1502, 1269, 587, 1515]
        }, {
          name: 'Un-Branded',
          data: [992, 2894, 2252, 774, 889, 360, 2645, 264, 1486, 1656, 4181, 271, 2306, 2455, 0, 157, 2958, 1853, 844, 780, 1456, 1416, 2902, 1961]
        }],
        chartOptions: {
          responsive: [{
            breakpoint: 360,
            options: {},
          }],
          title: {
            text: 'Last 24 Weeks Branded sales',
            style: {
              fontSize:  '24px',
              color:  '#304758',
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#00b300', '#ffa31a'],
          stroke: {
            width: 3,
            curve: 'smooth',
          },
          tooltip: {
            y: {
              formatter: function(value, val) {
                let series1 = val.series[val.seriesIndex][val.dataPointIndex];
                let oppIndex = val.seriesIndex == 1 ? 0 : 1;
                let series2 = val.series[oppIndex][val.dataPointIndex];
                let percentage = ((value / (series1 + series2)) * 100).toFixed(1);
                return `${value} KG (${percentage}%)`;
              },
            },
          },
          grid: {
            show: true,
            row: {
              colors: ['#fff', '#f2f2f2']
            }
          },
          xaxis: {
            labels: {
              rotate: -45
            },
            categories: ['30-5-Sep-2021','23-29-Aug-2021','16-22-Aug-2021','9-15-Aug-2021','2-8-Aug-2021','26-1-Aug-2021','19-25-Jul-2021','12-18-Jul-2021','5-11-Jul-2021','28-4-Jul-2021','21-27-Jun-2021','14-20-Jun-2021','7-13-Jun-2021','31-6-Jun-2021','24-30-May-2021','17-23-May-2021','10-16-May-2021','3-9-May-2021','26-2-May-2021','19-25-Apr-2021','12-18-Apr-2021','5-11-Apr-2021','29-4-Apr-2021','22-28-Mar-2021'
            ],
            tickPlacement: 'on'
          },
          yaxis: {
            title: {
              text: 'Quantity',
            },
          },
        },
      };
    },
  };
</script>