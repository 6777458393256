<template>
  <v-container fluid>
    <div style="overflow:auto;">
      <WeeklySales />
    </div>
    <div style="overflow:auto;">
      <WeeklyBrandedSales />
    </div>
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <WeeklyProfit />
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <MonthlyProfit />
      </v-col>
    </v-row>
    <div style="overflow:auto;">
      <WeeklyCustomers />
    </div>
  </v-container>
</template>

<script>
  import WeeklySales from './charts/24WeekSales';
  import WeeklyBrandedSales from './charts/24WeekBrandedSales';
  import WeeklyProfit from './charts/6WeeksProfit';
  import MonthlyProfit from './charts/6MonthsProfit';
  import WeeklyCustomers from './charts/24WeeksCustomers';

  export default {
    name: 't-dashboard-admin',
    components: {
      WeeklySales,
      WeeklyBrandedSales,
      WeeklyProfit,
      MonthlyProfit,
      WeeklyCustomers
    },
  };
</script>