<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="6" md="4" lg="4" class="py-0">
        <v-card flat class="mb-4">
          <v-card-title>
            Collection Status
          </v-card-title>
          <v-card-text v-for="(status, index) in collectionStatus" :key="index" class="subtitle-2">
            <span class="pr-4 body-1 blue-grey--text text--darken-4">{{status.collected_by_name}}</span>
            <span class="pr-4 font-weight-bold body-1">{{status.amount}}</span> 
            <span>
              <v-btn small color="blue-grey darken-1" class="white--text"
                @click="depositClicked(status.collected_by_id, status.amount)">
                Deposit
              </v-btn>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
      hide-default-footer
      :headers="headers"
      :items="collections"
      item-key="name"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: "t-allcollection-table-shared",
    data() {
      return {
        collectionStatus: [],
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Quick Ref', value: 'quick_ref' },
          { text: 'Amount', value: 'amount' },
          { text: 'Collected By', value: 'collected_by' },
          { text: 'Collected Date', value: 'collected_date' },
        ],
        collections: [
          {
            id: 1,
            name: 'Chandra sekar',
            quick_ref: 'chandru Trichy',
            amount: '342',
            collected_by: 'Chandru',
            collected_by_id: 1,
            collected_date: '2021-07-01',
          },
          {
            id: 2,
            name: 'Raja sekar',
            quick_ref: 'Raja Trichy',
            amount: '865',
            collected_by: 'Chandru',
            collected_by_id: 1,
            collected_date: '2019-07-02',
          },
          {
            id: 3,
            name: 'Sathish VPK',
            quick_ref: 'sevapet',
            amount: '796',
            collected_by: 'Gokul',
            collected_by_id: 2,
            collected_date: '2021-07-14',
          },
          {
            id: 4,
            name: 'Mohan store',
            quick_ref: 'chandru Trichy',
            amount: '364',
            collected_by: 'Chandru',
            collected_by_id: 1,
            collected_date: '2021-05-01',
          },
          {
            id: 5,
            name: 'dept store',
            quick_ref: 'chandru Trichy',
            amount: '458',
            collected_by: 'Chandru',
            collected_by_id: 1,
            collected_date: '2021-01-01',
          },
          {
            id: 6,
            name: 'some store',
            quick_ref: 'chandru Trichy',
            amount: '155',
            collected_by: 'Gokul',
            collected_by_id: 2,
            collected_date: '2006-07-01',
          },
          {
            id: 7,
            name: 'chennai store',
            quick_ref: 'chandru Trichy',
            amount: '320',
            collected_by: 'Gokul',
            collected_by_id: 2,
            collected_date: '2015-06-01',
          },
          {
            id: 8,
            name: 'pondy',
            quick_ref: 'chandru Trichy',
            amount: '420',
            collected_by: 'Chandru',
            collected_by_id: 1,
            collected_date: '2021-07-07',
          },
          {
            id: 9,
            name: 'lab',
            quick_ref: 'chandru Trichy',
            amount: '32',
            collected_by: 'Chandru',
            collected_by_id: 1,
            collected_date: '2021-10-01',
          },
          {
            id: 10,
            name: 'oil mill',
            quick_ref: 'chandru Trichy',
            amount: '232',
            collected_by: 'Gokul',
            collected_by_id: 2,
            collected_date: '2020-07-01',
          },
        ],
      }
    },
    methods: {
      depositClicked(id, amount) {
        console.log(id, amount);
      },
    },
    created() {
      this.collectionStatus = _.chain(this.collections)
        .groupBy('collected_by_id')
        .map((result, groupBy) => {
          return {
            collected_by_id: groupBy,
            collected_by_name: result[0] && result[0].collected_by,
            amount: '₹ ' + result.map(x => x.amount).reduce((a, b) => parseFloat(a) + parseFloat(b)),
          };
        }).value();
    },
  };
</script>
