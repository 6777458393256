<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="3" lg="3"
        v-for="(list, index) in navList" :key="index">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card :elevation="hover ? 6 : 2" rounded :class="{ 'on-hover': hover }">
            <v-card-title class="text-center justify-center grey lighten-2">
              <v-icon>{{list.iconList[0]}}</v-icon>
              <v-icon class="px-7">{{list.iconList[1]}}</v-icon>
              <v-icon class="pr-7">{{list.iconList[2]}}</v-icon>
              <v-icon>{{list.iconList[3]}}</v-icon>
            </v-card-title>
            <v-card-title class="text-center justify-center grey lighten-4">
              <v-btn @click="handleNavigation(list)" text>{{list.title}}</v-btn>
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 't-admin-home',
    data() {
      return {
        navList: [
          {title: 'Dashboard', component: 't-dashboard', iconList: ['mdi-chart-line', 'mdi-chart-bar', 'mdi-chart-pie', 'mdi-chart-histogram']},
          {title: 'Enquiry', component: 't-enquiry', iconList: ['mdi-phone-in-talk', 'mdi-calendar-text', 'mdi-calendar-clock', 'mdi-calendar-today']},
          {title: "Today's Delivery", component: 't-todaydelivery', iconList: ['mdi-bike', 'mdi-car-connected', 'mdi-bus', 'mdi-castle']},
          {title: 'All Collections', component: 't-allcollection', iconList: ['mdi-wallet-travel', 'mdi-bank', 'mdi-briefcase-check', 'mdi-briefcase-download']},
          {title: 'Pending Customers', component: 't-pendingcustomers', iconList: ['mdi-account-box-outline', 'mdi-account-alert', 'mdi-alert-octagon', 'mdi-bullhorn']},
          {title: 'Manage Inventory', component: 't-manageinventory', iconList: ['mdi-factory', 'mdi-layers', 'mdi-package-variant', 'mdi-package-variant-closed']},
          {title: 'Manage Customers', component: 't-managecustomers', iconList: ['mdi-account-plus', 'mdi-account-check', 'mdi-account-search', 'mdi-account-remove']},
          {title: 'Sales', component: 't-sales', iconList: ['mdi-walk', 'mdi-weight-kilogram', 'mdi-shopping', 'mdi-tag-multiple']},
        ],
      };
    },
    methods: {
      handleNavigation(navigate) {
        this.$store.dispatch('setComponent', {name: navigate.component, title: navigate.title});
      },
    },
  };
</script>