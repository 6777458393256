<template>
  <v-container fluid>
    
  </v-container>
</template>

<script>
  export default {
    name: 't-enquiry-form-shared',
  };
</script>