<template>
  <v-container fluid fill-height>
    <v-row justify="space-around" class="justify-center">
      <v-col cols="12" sm="6" md="6" lg="6" align-self="center" class="text-center">
        <v-img class="d-inline-block" width="70%" src="../../assets/tlogo.png" />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="text-center">
        <v-card class="width-login d-inline-block" rounded>
          <v-card-title class="text-h5 font-weight-regular primary white--text">
            Login
          </v-card-title>
          <v-form ref="form" v-model="form" @submit.prevent="submit">
            <v-card-text class="mt-5 pb-0">
              <v-text-field
                label="Mobile Number"
                placeholder="Enter Mobile number"
                :rules="[rules.userId]"
                outlined
                counter="10"
                v-model="loginForm.mobile"
              ></v-text-field>
              <v-text-field
                label="Password"
                placeholder="Enter Password"
                :rules="[rules.required, rules.password]"
                outlined
                counter="15"
                v-model="loginForm.password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="primary" type="submit"
                :loading="loading" :disabled="loading" @click="loginClick">Submit</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import rules from '../../utilities/validator';

  export default {
    name: 't-login-page',
    data() {
      return {
        rules,
        form: false,
        loginForm: {
          mobile: '',
          password: '',
        },
        loading: false,
      };
    },
    methods: {
      submit() {
        this.$refs.form.validate();
      },
      loginClick() {
        const self = this;
        this.loading = true;
        this.$router.push({name: 'admin'});
        setTimeout(function () {
          self.loading = false;
          self.$store.dispatch('setAlert', {
            status: 'primary',
            show: true,
            message: 'Logged in successfully',
          });
        }, 5000);
      },
    },
  };
</script>

<style scoped>
  .width-login {
    width: 300px;
  }
</style>