<template>
    <PendingCustomers />
  </template>
  
  <script>
    import PendingCustomers from "../../shared/PendingCustomers";

    export default {
      name: 't-pending-customers-admin',
      components: {
        PendingCustomers,
      },
    };
  </script>